import React, { useState } from "react";
import FileUploadSection from "../components/FileUploadSection";
import SidebarComponent from "../components/SidebarComponent";
import WelcomeModal from "../components/WelcomeModal";
import AdBanner from "../components/AdBanner";
import AppLayout from "./AppLayout";

export default function ModifyData() {
  
    // const [userdata, setUserdata] = useState(null);
    // const [isUserCheckComplete, setIsUserCheckComplete] = useState(false);
    const [showModal, setShowModal] = useState(true);
    // const user = useUser();
    // const [ userFullName, setUserFullName ] = useState(null);
    
    // Effect for handling user data retrieval and creation
    // useEffect(() => {
    //   // Asynchronous function to check if the user exists in the database
    //   async function checkAndHandleNewUser() {
    //     if (isUserCheckComplete) return; 
  
    //     const userEndpoint = `${apiUrl}/api/v0/students/api/v1/get_student/${user.user.primaryEmailAddress.emailAddress}`; //FIX THIS
  
    //     try {
    //       const response = await axios.get(userEndpoint);
  
    //       // Assuming the response data is the user data you want to set
    //       setUserdata(response.data);
    //       setUserFullName(response.data.email);
    //       // console.log("User found in Database.");
    //       setIsUserCheckComplete(true); // Set check as complete
  
    //     } catch (error) {
    //       if (axios.isAxiosError(error) && error.response?.status === 404) {
    //         console.log("User not found in Database. Creating user...");
    //         afterUserCreationWithClerk(user);
    //       } else {
    //         // Handle other errors (network issues, server errors, etc.)
    //         console.error("Error fetching user from MongoDB:", error);
    //         setIsUserCheckComplete(true); // Set check as complete
    //       }
    //     }
    //   }
  
    //   // Execute the function
    //   checkAndHandleNewUser();
  
    //   // Dependency array to re-run effect when `user` changes
    // }, [user]);
  
    // console.log(userdata)
  
    
      return (
          <>
          <AppLayout>
          <div>
            
            <div >
              <div className="min-h-full">
                <div className="bg-indigo-600 pb-64">
                  <header className="py-10">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                      <h1 className="text-3xl font-bold tracking-tight text-white">Modify Data</h1>
                    </div>
                  </header>
                </div>
      
                <div className="-mt-64">
                  <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                    <FileUploadSection />
                  </div>
                </div>
              </div>
            </div>
            
  
            
          </div>
          </AppLayout>
          
          </>
          
        );
  }