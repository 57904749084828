import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './table';


function InventoryTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [inventoryData, setInventoryData] = useState([]);

    const utilityName = process.env.REACT_APP_UTILITY_NAME;
    const apiURL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${apiURL}/api/v1/inventory/${utilityName}`)
            .then(response => {
                //keep the json to show the data as a table
                setInventoryData(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the inventory data:', error);
                setError(error);
      
            })
            .finally(() => {
                setIsLoading(false);
            });
      }, []);

        if (isLoading) {
            return <div>Loading...</div>;
        }

  return (

    <Table striped className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
      <TableHead>
        <TableRow>
          <TableHeader>Service Area</TableHeader>
          <TableHeader>Transformers</TableHeader>
          <TableHeader>Cables</TableHeader>
            <TableHeader>Splices</TableHeader>
            <TableHeader>Terminations</TableHeader>
            <TableHeader>Fuses</TableHeader>
            <TableHeader>Relays</TableHeader>
            <TableHeader>Switches</TableHeader>
            <TableHeader>Poles</TableHeader>
            <TableHeader>Insulators</TableHeader>
            <TableHeader>Crossarms</TableHeader>
            <TableHeader>PPE</TableHeader>
            <TableHeader>Radio</TableHeader>
            <TableHeader>Handtools</TableHeader>
            <TableHeader>Warehouse</TableHeader>
            </TableRow>
      </TableHead>
      <TableBody>
        {inventoryData.map((user) => (
          <TableRow key={user.SERVICE_AREA}>
            <TableCell className="font-medium">{user.SERVICE_AREA}</TableCell>
            <TableCell>{user.TRANSFORMER_OH}</TableCell>
            <TableCell className="text-zinc-500">{user.CABLES} ft</TableCell>
            <TableCell>{user.SPLICES}</TableCell>
            <TableCell>{user.CABLE_TERMINATIONS}</TableCell>
            <TableCell>{user.OH_FUSES}</TableCell>
            <TableCell>{user.RELAYS}</TableCell>
            <TableCell>{user.OH_SWITCHES}</TableCell>
            <TableCell>{user.POLES}</TableCell>
            <TableCell>{user.INSULATOR}</TableCell>
            <TableCell>{user.CROSSARM}</TableCell>
            <TableCell>{user.PPE}</TableCell>
            <TableCell>{user.RADIO}</TableCell>
            <TableCell>{user.HANDTOOLS}</TableCell>
            <TableCell>{user.WAREHOUSE}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default InventoryTable;