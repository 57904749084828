import { ChevronRightIcon } from '@heroicons/react/20/solid'

export default function LandingPage() {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <img
                  className="h-24"
                  src="https://res.cloudinary.com/dti7egpsg/image/upload/v1702500357/SOFIA/logo512_fxhxwh.png"
                  alt="SOFIA Logo"
                />
                <div className="mt-24 sm:mt-32 lg:mt-16">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Storm Outage Forecasting<br className="hidden md:inline " /> and impact assessments.
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    First of its kind, this tool uses GeoAI technology to help Utilities to better prepare for storm outages and to assess the impact of storm outages on their customers.
                  </p>
                  <div className="mt-10 flex items-center gap-x-6">
                    <a
                      href="/home"
                      className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Get Started
                    </a>
                    <a href="mailto:chanda.sayonsom@gmail.com" className="text-sm font-semibold leading-6 text-gray-900">
                      Email Support <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            {/* insert an image here */}
            <img
              className="w-full h-full object-cover
                sm:rounded-lg lg:rounded-none"
                src="https://res.cloudinary.com/dti7egpsg/image/upload/v1702500254/SOFIA/Screenshot_2023-12-13_at_1.43.59_PM_kjw7xe.png"
                alt="SOFIA Landing Page Image"
                />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  )
}
