import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  SignIn,
  SignUp,
  UserButton,
} from "@clerk/clerk-react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Home from "./pages/Home";
import LandingPage from "./pages/LandingPage";
import SignInButton from "./components/SignInButton";
import SignOutButton from "./components/SignOutButton";
import ForecastToday from "./forecasts/today";
import ModifyData from "./pages/ModifyData";
import NotInDemo from "./pages/NotInDemo";
import Custom404 from "./pages/Custom404";
import ScenarioBuilder from "./pages/ScenarioBuilder";
import Imagery from "./pages/Imagery";


 
// if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
//   throw new Error("Missing Publishable Key")
// }
 
const clerkPubKey = "pk_test_YW1hemluZy1raXR0ZW4tOTYuY2xlcmsuYWNjb3VudHMuZGV2JA" // process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
 
function PublicPage() {
  return (
    <>
      <LandingPage />
    </>
  );
}
 

 
function ClerkProviderWithRoutes() {
  const navigate = useNavigate();
 
  return (
    // <ClerkProvider
    //   publishableKey={clerkPubKey}
    //   navigate={(to) => navigate(to)}
    // >
      <Routes>
        <Route path="/" element={<PublicPage />} />
        <Route
          path="/sign-in/*"
          element={<SignIn routing="path" path="/sign-in" />}
        />
        <Route
          path="/sign-up/*"
          element={<SignUp routing="path" path="/sign-up" />}
        />
        <Route
          path="/home"
          element={
          <>
            <Home />
            {/* <AuthenticatedTemplate>
              <Home />
            </AuthenticatedTemplate>
             <UnauthenticatedTemplate>
             <p>You are not logged in!</p>
             <SignInButton />
           </UnauthenticatedTemplate> */}
          </>
          }
        />
        <Route
          path="/forecast/today"
          element={
          <>
          <ForecastToday />
            {/* <AuthenticatedTemplate>
              <ForecastToday />
            </AuthenticatedTemplate>
             <UnauthenticatedTemplate>
             <p>You are not logged in!</p>
             <SignInButton />
           </UnauthenticatedTemplate> */}
          </>
          }
        />
        <Route
          path="/imagery"
          element={
          <>
          <Imagery />
            {/* <AuthenticatedTemplate>
              <ForecastToday />
            </AuthenticatedTemplate>
             <UnauthenticatedTemplate>
             <p>You are not logged in!</p>
             <SignInButton />
           </UnauthenticatedTemplate> */}
          </>
          }
        />
        <Route
          path="*"
          element={
          <>
          <Custom404 />
            {/* <AuthenticatedTemplate>
              <ForecastToday />
            </AuthenticatedTemplate>
             <UnauthenticatedTemplate>
             <p>You are not logged in!</p>
             <SignInButton />
           </UnauthenticatedTemplate> */}
          </>
          }
        />
        <Route
          path="/not-in-demo"
          element={
          <>
          <NotInDemo />
            {/* <AuthenticatedTemplate>
              <ForecastToday />
            </AuthenticatedTemplate>
             <UnauthenticatedTemplate>
             <p>You are not logged in!</p>
             <SignInButton />
           </UnauthenticatedTemplate> */}
          </>
          }
        />
        <Route
          path="/scenario-builder"
          element={
          <>
          <ScenarioBuilder />
            {/* <AuthenticatedTemplate>
              <ForecastToday />
            </AuthenticatedTemplate>
             <UnauthenticatedTemplate>
             <p>You are not logged in!</p>
             <SignInButton />
           </UnauthenticatedTemplate> */}
          </>
          }
        />
        <Route
          path="/forecast/:dateParam"
          element={
          <>
          <ForecastToday />
            {/* <AuthenticatedTemplate>
              <ForecastToday />
            </AuthenticatedTemplate>
             <UnauthenticatedTemplate>
             <p>You are not logged in!</p>
             <SignInButton />
           </UnauthenticatedTemplate> */}
          </>
          }
        />
        <Route
          path="/modify-data"
          element={
          <>
          <ModifyData />
            {/* <AuthenticatedTemplate>
              <ModifyData />
            </AuthenticatedTemplate>
             <UnauthenticatedTemplate>
             <p>You are not logged in!</p>
             <SignInButton />
           </UnauthenticatedTemplate> */}
          </>
          }
        />
      </Routes>
    // </ClerkProvider>
  );
}
 
function App() {
  return (
    <BrowserRouter>
      <ClerkProviderWithRoutes />
    </BrowserRouter>
  );
}
 
export default App;