import React, { useState, useEffect } from 'react';
import ExperimentCard from './ExperimentCard';

const ExperimentsRow = ( {forecastData} ) => {
//   const [experiments, setExperiments] = useState([]);

//   useEffect(() => {
//     // Fetching data from an API endpoint
//     const fetchData = async () => {
//       try {
//         const response = await fetch("YOUR_API_ENDPOINT_HERE");
//         const data = await response.json();

//         setExperiments(data);
//       } catch (error) {
//         console.error("There was an error fetching the data", error);
//       }
//     }

//     fetchData();
//   }, []);

    // update the experiments array with the forecastData
    const experiments = forecastData;

    return (
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
        {experiments.map((experiment) => (
          <ExperimentCard
            key={experiment.date}
            name={`${experiment.date}`}
            dueDate={experiment.date}
            outages={experiment.forecastedOutages}
            wind={experiment.maxWindGusts}
            precipitation={experiment.meanHourlyPrecipitation.toFixed(2)}
            temp={experiment.avgTemp.toFixed(2)} // Assuming status is a string, converting avgTemp to string
            url={`/forecast/${experiment.date}`} // Assuming you have a URL structure for more details
          />
        ))}
      </div>
    );
}

export default ExperimentsRow;
