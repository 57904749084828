import React, { useState, useEffect } from 'react';

import WeatherBarChart from './WeatherBarChart';
import FileUploadSection from './FileUploadSection';
import ResiliencyOptionsCardStrip from './ResiliencyOptionsCardStrip';
import InventoryAndCrewCardStrip from './InventoryAndCrewCardStrip';
import VegetationManagementCardStrip from './VegetationManagementCardStrip';
import CustomerPieChart from './CustomerPieChart';
import Spinner from './Spinner';
import SubstationRiskTable from './SubstationRiskTable';


function InteractiveSide( {data, substationData} ) {
  
// useEffect to process the data into various segments

const [geoJsonData, setGeoJsonData] = useState([]);

const [installedSolarStorage, setInstalledSolarStorage] = useState(0);
const [hvV2G, setHvV2G] = useState(0);
const [customerOwnedDER, setCustomerOwnedDER] = useState(0);
const [transformers, setTransformers] = useState(0);
const [trucks, setTrucks] = useState(0);
const [poles, setPoles] = useState(0);
const [wire, setWire] = useState(0);
const [avgRightOfWay, setAvgRightOfWay] = useState(0);
const [avgVegetationDensity, setAvgVegetationDensity] = useState(0);
const [lastVMTrim, setLastVMTrim] = useState(0);
const [totalCommercialCustomers, setTotalCommercialCustomers] = useState(0);
const [totalIndustrialCustomers, setTotalIndustrialCustomers] = useState(0);
const [totalResidentialCustomers, setTotalResidentialCustomers] = useState(0);

const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  setIsLoading(true);
  if (data && data.features && data.features.length > 0) {
    setGeoJsonData(data);
    const firstTimestamp = Object.keys(data.features[0].properties.valuesOverTime)[0];
    // setCurrentTime(firstTimestamp);

    let totalInstalledSolarStorage = 0, totalHvV2G = 0, totalCustomerOwnedDER = 0, totalTransformers = 0, totalTrucks = 0, totalPoles = 0, totalWire = 0, totalAvgRightOfWay = 0, totalAvgVegetationDensity = 0, totalLastVMTrim = 0;
    let tempTotalCommercialCustomers = 0;
    let tempTotalIndustrialCustomers = 0;
    let tempTotalResidentialCustomers = 0;

    data.features.forEach(feature => {
      const values = feature.properties;
      if (values) {
        totalInstalledSolarStorage += values['FTM_DER_MW'] || 0;
        totalHvV2G += values['HeavyDutyEV'] || 0;
        totalCustomerOwnedDER += values['BTM_DER_MW'] || 0;
        totalTransformers += 0;
        totalTrucks += 0;
        totalPoles += 0;
        totalWire += 0;
        totalAvgRightOfWay += 0;
        totalAvgVegetationDensity += values['AvgVegDensity'] || 0;
        totalLastVMTrim += 0;
        tempTotalCommercialCustomers += values['CommercialCustomers'] || 0;
      tempTotalIndustrialCustomers += values['IndustrialCustomers'] || 0;
      tempTotalResidentialCustomers += values['ResidentialCustomers'] || 0;
      }
    });

    setInstalledSolarStorage(totalInstalledSolarStorage);
    setHvV2G(totalHvV2G);
    setCustomerOwnedDER(totalCustomerOwnedDER);
    setTransformers(totalTransformers);
    setTrucks(totalTrucks);
    setPoles(totalPoles);
    setWire(totalWire);
    setAvgRightOfWay(totalAvgRightOfWay);
    setAvgVegetationDensity(totalAvgVegetationDensity);
    setLastVMTrim(totalLastVMTrim);
    setTotalCommercialCustomers(tempTotalCommercialCustomers);
  setTotalIndustrialCustomers(tempTotalIndustrialCustomers);
  setTotalResidentialCustomers(tempTotalResidentialCustomers);

    setIsLoading(false);
  } else {
    setIsLoading(false);
  }
  
}, [data]);  // Add myGeoJSON as a dependency


if (isLoading) {
  return <Spinner />; // Render Spinner while data is loading
}



  return (
    <>
    <div className="relative overflow-y-auto">
      {/* Add your cards here */}

      {/* if geoJson is not undefined and empty then show the following, else dont */}

      {geoJsonData && geoJsonData.features && geoJsonData.features.length > 0 ? (
        <>
        <div className="bg-white rounded-lg mb-4">
            <div className="grid grid-cols-2 gap-4 p-4">
                <div className="chart p-4 rounded-lg shadow-md"><WeatherBarChart jsonData={geoJsonData} /></div>
                <div className="chart p-4 rounded-lg shadow-md"><WeatherBarChart jsonData={geoJsonData} /></div>
                <div className="chart p-4 rounded-lg shadow-md"><WeatherBarChart jsonData={geoJsonData} /></div>
                <div className="chart p-4 rounded-lg shadow-md"><CustomerPieChart comm_cust={totalCommercialCustomers} indus_cust={totalIndustrialCustomers} resi_cust={totalResidentialCustomers}/></div>
            </div>
                </div>
        <div className="bg-white rounded-lg mb-4">
                  <div className="bg-white p-6">
                    <CustomerPieChart totalCommercialCustomers={totalCommercialCustomers} totalIndustrialCustomers={totalIndustrialCustomers} totalResidentialCustomers={totalResidentialCustomers} />
                  </div>
                </div>
        </>
      ) : (
        <div className="bg-white rounded-lg mb-4">
                  <div className="bg-white p-6">
                    <FileUploadSection />
                  </div>
                </div>
      )}
      
      
    </div>
    
    <div className="relative bg-gray-100 ">
    <div className="bg-white rounded-lg mb-4">
                  <div className="bg-white p-6">
                    <ResiliencyOptionsCardStrip installedSolarStorage={installedSolarStorage} hvV2G={hvV2G} customerOwnedDER={customerOwnedDER}/>
                    <SubstationRiskTable substationData={substationData}/>
                    <InventoryAndCrewCardStrip />
                    {/* <VegetationManagementCardStrip /> */}
                    {/* <ForecastActivityTable /> */}
                    </div>
                </div>
    </div>
    </>
    
  );
}

export default InteractiveSide;