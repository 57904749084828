import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

// import myGeoJSON from '../sample2023new.json';
import OutageForecastCardStrip from '../components/OutageForecastCardStrip';
import Spinner from './Spinner';

const MapPlayer = ( {myGeoJSON} ) => {
  // Initialize state without any condition
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(null);
  const [momentary, setMomentary] = useState(0);
  const [sustained, setSustained] = useState(0);
  const [truckRolls, setTruckRolls] = useState(0);
  const [customers, setCustomers] = useState(0);

  

  useEffect(() => {
    setIsLoading(true);
  
    if (myGeoJSON && myGeoJSON.features && myGeoJSON.features.length > 0) {
      const firstTimestamp = Object.keys(myGeoJSON.features[0].properties.valuesOverTime)[0];
      setCurrentTime(firstTimestamp);
  
      let totalCustomers = 0, totalMomentary = 0, totalSustained = 0, totalTruckRolls = 0;
  
      myGeoJSON.features.forEach(feature => {
        const values = feature.properties.valuesOverTime[firstTimestamp];
        if (values) {
          totalCustomers += values['CUSTOMERS_IMPACTED'] || 0;
          totalMomentary += values['MOMENTARY_INTERRUPTIONS'] || 0;
          totalSustained += values['SUSTAINED_INTERRUPTIONS'] || 0;
          totalTruckRolls += values['TRUCK_ROLLS'] || 0;
        }
      });
  
      setCustomers(totalCustomers);
      setMomentary(totalMomentary);
      setSustained(totalSustained);
      setTruckRolls(totalTruckRolls);

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [myGeoJSON]);  // Add myGeoJSON as a dependency
  
  


  if (isLoading) {
    return <Spinner />; // Render Spinner while data is loading
  }

  const getColor = (forecastOutages) => {
    
    if (forecastOutages === 0) {
      return '#456E9D'; // No outages
    } else if (forecastOutages < 6) {
      return '#F08326'; // Few outages
    } else {
      return '#DD4D4F'; // Many outages
    }
  };

  // Function to apply the style to each feature

  const style = (feature) => {
    if (feature.properties.valuesOverTime && feature.properties.valuesOverTime[currentTime]) {
      const forecastOutages = feature.properties.valuesOverTime[currentTime]['SUSTAINED_INTERRUPTIONS'] + feature.properties.valuesOverTime[currentTime]['MOMENTARY_INTERRUPTIONS'];
      
      return {
        fillColor: getColor(forecastOutages),
        weight: 2,
        opacity: 1,
        color: 'white',
        fillOpacity: 0.7
      };
    }
    return {};
  };



  

  // console.log(myGeoJSON.features[0].properties.valuesOverTime);
  // Define timeStamps and other variables without calling any hooks
  const featuresExist = myGeoJSON && Array.isArray(myGeoJSON.features) && myGeoJSON.features.length > 0;
  // Check if valuesOverTime exist in all features
const valuesOverTimeExist = featuresExist && myGeoJSON.features.every(feature =>
  feature.properties && 'valuesOverTime' in feature.properties);
  const timeStamps = valuesOverTimeExist ? Object.keys(myGeoJSON.features[0].properties.valuesOverTime) : [];

  // After all hooks have been called, you can use conditions to perform operations
  // If there are no timestamps, set the first timestamp to state
  if (!currentTime && timeStamps.length > 0) {
    setCurrentTime(timeStamps[0]);
    
  }

  // If there are no timeStamps, we display a message and do not render the MapContainer
  if (timeStamps.length === 0) {
    return <div>No data available to display the map.</div>;
  }

  // Map over features and update the currentData for each feature
  // Update filteredGeoJSON to correctly handle each feature's valuesOverTime
const filteredGeoJSON = {
  ...myGeoJSON,
  features: myGeoJSON.features.map(feature => {
    const currentData = feature.properties.valuesOverTime[currentTime] || {};
    
    return {
      ...feature,
      properties: { ...feature.properties, currentData }
    };
  })
};

  

  
  // Handler for the slider change
  const handleTimeChange = (event) => {
    // Update the currentTime state to the selected time
    const selectedTime = timeStamps[event.target.value];
    setCurrentTime(selectedTime);

    // New code to calculate and set new values
  let totalSustained = 0, totalMomentary = 0, totalTruckRolls = 0, totalCustomers = 0;
  filteredGeoJSON.features.forEach(feature => {
    const data = feature.properties.valuesOverTime[selectedTime];
    console.log(data);
    if (data) {
      totalSustained += data['SUSTAINED_INTERRUPTIONS'] || 0;
      totalMomentary = data['MOMENTARY_INTERRUPTIONS'] || 0;
      totalTruckRolls += data['TRUCK_ROLLS'] || 0;
      totalCustomers = data['CUSTOMERS_IMPACTED'] || 0;
    }
  });

  setSustained(totalSustained);
  setMomentary(totalMomentary);
  setTruckRolls(totalTruckRolls);
  setCustomers(totalCustomers);

  };

  const formattedTime = currentTime ? format(new Date(currentTime), 'HH:mm') : '';
const relativeTime = currentTime ? formatDistanceToNow(new Date(currentTime)) : '';

  return (
    <div>
      
      <div class="relative mb-6">
        <p className='text-xl font-medium text-gray-900'>Analysis Time</p>
        <p className='text-4xl font-black text-gray-500'>{formattedTime}</p>
    <p className='text-2xl text-gray-500'>{relativeTime}</p>
        </div>
        <div className="bg-white rounded-lg mb-4">
                  <div className="bg-white p-6">
                    <OutageForecastCardStrip momentary={momentary} sustained={sustained} truckRolls={truckRolls} customers={customers} />
                    </div>
                </div>

                <div class="relative mb-6">
                  <input type="range" min="0" max={timeStamps.length - 1} value={timeStamps.indexOf(currentTime)} onChange={handleTimeChange} class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"/>
      <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-0 -bottom-6">Hour: 0</span>
    <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-1/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">Hour: 8</span>
    <span class="text-sm text-gray-500 dark:text-gray-400 absolute start-2/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">Hour: 16</span>
    <span class="text-sm text-gray-500 dark:text-gray-400 absolute end-0 -bottom-6">Hour: {timeStamps.length}</span>
      </div>

      <MapContainer center={[29.3, -81.1]} zoom={8} scrollWheelZoom={true} style={{ height: '600px', width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <GeoJSON data={filteredGeoJSON} style={style} />

        

      </MapContainer>
      
      
    </div>
  );
};

export default MapPlayer;
