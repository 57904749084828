import React from 'react';

function ThreeDContainer() {
  return (
    <div className="fixed bg-gray-200 h-screen w-1/2">
      
    </div>
  );
}

export default ThreeDContainer;
