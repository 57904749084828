import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from './dropdown'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

const WeatherBarChart = ({ jsonData }) => {
  const [selectedFeature, setSelectedFeature] = useState(null);

  

  const handleDropdownSelection = (serviceAreaName) => {
    const feature = jsonData.features.find(f => f.properties.ServiceAreaName === serviceAreaName);
    setSelectedFeature(feature);
  };

  // useEffect hook to set the default feature when jsonData is available
  useEffect(() => {
    if (jsonData && jsonData.features && jsonData.features.length > 0) {
      setSelectedFeature(jsonData.features[0]);
    }
  }, [jsonData]); 

  const getChartData = () => {
    if (!selectedFeature) return { temperature: [], windgust: [], relative_humidity: [] };
  
    // Transform the valuesOverTime object into Highcharts series data
    const chartData = Object.entries(selectedFeature.properties.valuesOverTime).reduce((acc, [time, data]) => {
      // Convert the time string into a timestamp for Highcharts
      const timestamp = new Date(time).getTime();
      
      acc.temperature.push([timestamp, data.temperature]);
      acc.windgust.push([timestamp, data.windgust]);
      acc.relative_humidity.push([timestamp, data.relative_humidity]);
      
      return acc;
    }, { temperature: [], windgust: [], relative_humidity: [] });
  
    return chartData;
  };

  const chartData = getChartData();

  const chartOptions = {
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Time'
      }
    },
    title: {
      text: `Data for ${selectedFeature ? selectedFeature.properties.ServiceAreaName : ''}`
    },
    series: [{
      name: 'Temperature',
      data: chartData.temperature  // Use the temperature array directly
    }, {
      name: 'Wind Gust',
      data: chartData.windgust  // Use the windgust array directly
    }, {
      name: 'Humidity',
      data: chartData.relative_humidity  // Use the relative_humidity array directly
    }],
    // ... other Highcharts options
  };
  
  // Check if jsonData is defined
  if (!jsonData || !jsonData.features || jsonData.features.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <Dropdown>
      <DropdownButton outline>
        {selectedFeature ? selectedFeature.properties.ServiceAreaName : 'Select Area'}
        <ChevronDownIcon />
      </DropdownButton>
     
      <DropdownMenu>
        {jsonData.features.map((feature, index) => (
          <DropdownItem 
            key={index} 
            onClick={() => handleDropdownSelection(feature.properties.ServiceAreaName)}>
            {feature.properties.ServiceAreaName}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>

      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  );
};

export default WeatherBarChart;
