import React from 'react';
import { useSearchParams } from 'react-router-dom';
import StreetViewComponent from '../components/StreetViewComponent';
import AppLayout from './AppLayout';

function Imagery() {
    const [searchParams] = useSearchParams();
    const lat = searchParams.get('lat');
    const lon = searchParams.get('lon');

  return (
    <AppLayout>
         <StreetViewComponent lat={parseFloat(lat)} lon={parseFloat(lon)}  />
    </AppLayout>
  );
}

export default Imagery;