import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './table';
import { Switch , SwitchField } from './switch'
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { Label } from './fieldset'
function SubstationRiskTable( { substationData } ) {

    const navigate = useNavigate(); 

    const handleRowClick = (rowData) => {
        const { lat, lon } = rowData;
        navigate(`/imagery?lat=${lat}&lon=${lon}`); // Use navigate to redirect
      };
  

  return (
    <>
    <h3 className="text-base font-semibold leading-6 text-gray-900 mt-4">Substation Risks</h3>
    <Table bleed className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] mt-4">
      <TableHead>
        <TableRow>
          <TableHeader>Service Area</TableHeader>
          <TableHeader>Substation</TableHeader>
          <TableHeader>Latitude</TableHeader>
          <TableHeader>Longitude</TableHeader>
          <TableHeader>HV Side (kV)</TableHeader>
          <TableHeader>LV Side (kV)</TableHeader>
          <TableHeader>Flood Risk (15 ft)</TableHeader>
        <TableHeader>Flood Pumps</TableHeader>
            <TableHeader>Camera</TableHeader>
            <TableHeader>Feeders</TableHeader>
            <TableHeader>NS Slope</TableHeader>
            <TableHeader>EW Slope</TableHeader>
            <TableHeader>Aspect</TableHeader>
            <TableHeader>Elevation (ft)</TableHeader>

        </TableRow>
      </TableHead>
      <TableBody>
        {substationData.map((utility, index) => (
          <TableRow key={index} onClick={() => handleRowClick(utility)}>
            <TableCell>{utility.service_area}</TableCell>
            <TableCell>{utility.substation}</TableCell>
            <TableCell>{utility.lat}</TableCell>
            <TableCell>{utility.lon}</TableCell>
            <TableCell>{utility.hv_side}</TableCell>
            <TableCell>{utility.lv_side}</TableCell>
            <TableCell>{utility.flood_risk}</TableCell>
            <TableCell>{utility.flood_pumps}</TableCell>
            <TableCell>
                <SwitchField>
                    <Label>Live Camera</Label>
                    <Switch name="allow_embedding" />
                 </SwitchField>
            </TableCell>
            <TableCell>{utility.feeders}</TableCell>
            <TableCell>{utility.ns_slope}</TableCell>
            <TableCell>{utility.ew_slope}</TableCell>
            <TableCell>{utility.aspect}</TableCell>
            <TableCell>{utility.elevation}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </>
    
  );
}

export default SubstationRiskTable;
