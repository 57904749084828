import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Radio, RadioField, RadioGroup } from './radio';
import { Label } from './fieldset'



const contourData1 = {"type": "FeatureCollection", "features": [{"type": "Feature", "properties": {"VulnerabilityIndex": 0.09050843153705523, "ImpactIndex": 0.39428981231883664}, "geometry": {"type": "LineString", "coordinates": [[-81.23146467948202, 29.029343792726685], [-81.26900846943616, 29.0858308341285]]}}, {"type": "Feature", "properties": {"VulnerabilityIndex": 0.9466783444425299, "ImpactIndex": 0.3598393942628879}, "geometry": {"type": "LineString", "coordinates": [[-81.26900846943616, 29.0858308341285], [-81.2849927303022, 28.9933408309773]]}}, {"type": "Feature", "properties": {"VulnerabilityIndex": 0.6755709259175503, "ImpactIndex": 0.7284879973612429}, "geometry": {"type": "LineString", "coordinates": [[-81.2849927303022, 28.9933408309773], [-81.38369642458596, 29.124038871356685]]}}, {"type": "Feature", "properties": {"VulnerabilityIndex": 0.772127960612345, "ImpactIndex": 0.7428860021842265}, "geometry": {"type": "LineString", "coordinates": [[-81.38369642458596, 29.124038871356685], [-81.22929459255624, 28.94444089623348]]}}]}

const ScenarioResultsMap = () => {
  const [selectedContourData, setSelectedContourData] = useState(contourData1);
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

  const switchContourData = (event) => {
    const selectedValue = event;
    setSelectedContourData(selectedValue === 'data1' ? contourData1 : contourData1);
  };

  useEffect(() => {
    let localMap = null;
    const initializeMap = () => {
      const localMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/sayonmapbox/clri8blbs00dt01pdd8fwea0d',
        center: [-81.2609695306685, 29.04912874340847],
        zoom: 7,
      });
  
      localMap.on('load', () => {
        // Add the GeoJSON data as a source
        localMap.addSource('lines', {
          type: 'geojson',
          data: contourData1
        });
      
        // Add a heatmap layer
        localMap.addLayer({
          id: 'contour',
          type: 'heatmap',
          source: 'lines',
          paint: {
            'heatmap-weight': {
              property: 'VulnerabilityIndex',
              type: 'exponential',
              stops: [
                [0, 0],
                [1, 1]
              ]
            },
            'heatmap-intensity': {
              type: 'exponential',
              stops: [
                [0, 0],
                [1, 1]
              ]
            },
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(33,102,172,0)',
              0.2,
              'yellow',
              0.4,
              'green',
              0.6,
              'blue',
              0.8,
              'red',
              1,
              'orange'
            ],
          }
        });

        // Add a LineString layer
      localMap.addLayer({
        id: 'contour-linestring',
        type: 'line',
        source: 'lines',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#0e0e0e',  // Example color
          'line-width': 3
          // You can use data-driven styles here if needed
        }
      });
      });
      
  
      setMap(localMap);
    };
  
    if (!map) initializeMap();
    return () => {
      if (localMap) localMap.remove();
    };
  }, [map]);
  
  

  return (
    <div>
      <div ref={mapContainerRef} style={{ height: '100vh', width: '100%' }}></div>
      <div className="absolute bottom-4 right-4 p-4 z-50 bg-white rounded-lg shadow">
        <RadioGroup name="contourSelection" defaultValue="data1" onChange={switchContourData}>
          <RadioField>
            <Radio value="data1" />
            <Label>Outage Probability Heatmap</Label>
          </RadioField>
          <RadioField>
            <Radio value="data2" />
            <Label>Vegetation Risk</Label>
          </RadioField>
        </RadioGroup>
      </div>
    </div>
  );
};
  
export default ScenarioResultsMap;