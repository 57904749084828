import React, { useEffect, useRef } from 'react';

function StreetViewComponent({ lat, lon }) {
  const mapContainerRef = useRef(null);
  const apiKey = process.env.REACT_APP_MAPS_API;

  // Function to initialize the Google Map
  const initMap = () => {
    if (window.google && window.google.maps && mapContainerRef.current) {
      const mapOptions = {
        center: { lat, lng: lon },
        zoom: 20, // Higher zoom level for closer view
        mapTypeId: 'satellite', // Set satellite view as default
      };
      const map = new window.google.maps.Map(mapContainerRef.current, mapOptions);

      // Custom icon for larger marker
      const markerIcon = {
        url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png', // URL of the icon
        scaledSize: new window.google.maps.Size(40, 40), // Size of the icon
      };

      // Add a larger marker
      new window.google.maps.Marker({
        position: { lat, lng: lon },
        map: map,
        title: 'Substation Location',
        icon: markerIcon, // Use custom icon
      });
    }
  };

  // Load Google Maps script dynamically
  useEffect(() => {
    if (window.google && window.google.maps) {
      initMap(); // If script already loaded
      return;
    }
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    script.async = true;
    script.defer = true;
    script.onload = initMap;
    document.head.appendChild(script);
  }, [lat, lon, apiKey]);

  return <div ref={mapContainerRef} className="w-full h-screen" />;
}

export default StreetViewComponent;
