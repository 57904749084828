import React, { useState } from 'react';
import { Button } from './button';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from './dialog';
import { Field, Label } from './fieldset'
import { Input } from './input'

const FileUploadSection = () => {
  

  return (
    <div className="p-4 bg-white shadow rounded-lg">
      <UploadItem title="GIS File for Region-Level Analysis" fileName="Regions.zip" />
      <UploadItem title="GIS File for Secondary-Feeder Level Analysis" />
      <UploadItem title="Feeder to Customer Mapping File" fileName="Feeder-Customer_map.csv" />
      <UploadItem title="Asset Health and Asset-Feeder Mapping File" />
    </div>
  );
};

const UploadItem = ({ title, fileName }) => {
  let [isOpen, setIsOpen] = useState(false);
  return (
    <div className="mb-4">
      <div className="font-semibold text-gray-700 mb-1">{title}</div>
      <div className="flex items-center justify-between">
        <span className="text-sm font-medium text-blue-600">{fileName || 'No File Uploaded'}</span>
        <Button type="button" onClick={() => setIsOpen(true)}>
         Upload
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Update Data</DialogTitle>
        <DialogDescription>
          New file will be updated for all backend analytics and forecasting within 24 hours.
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>File Name</Label>
            <Input name="amount" placeholder="test.txt" />
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => setIsOpen(false)}>Upload</Button>
        </DialogActions>
      </Dialog>
      </div>
    </div>
  );
};

export default FileUploadSection;
