// WelcomeModal.js
import React, { useEffect } from 'react';
import { Badge } from './badge'

const WelcomeModal = ({ showModal, setShowModal }) => {
    useEffect(() => {
        // Add event listeners
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscapeKeyPress);
    
        // Remove event listeners on cleanup
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
          document.removeEventListener('keydown', handleEscapeKeyPress);
        };
      }, []);

  if (!showModal) {
    return null;
  }

  // Close modal on click outside
  const handleClickOutside = (event) => {
    if (event.target.id === 'my-modal') {
      setShowModal(false);
    }
  };

  // Close modal on escape key press
  const handleEscapeKeyPress = (event) => {
    if (event.key === 'Escape') {
      setShowModal(false);
    }
  };

  


  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
        <Badge color="rose">Sand-box Mode: Changes Not Saved</Badge>
          <h1 className="text-lg leading-6 font-medium text-gray-900">Demo Web app for Storm Outage Forecasting</h1>
          <div className="mt-2 px-7 py-3">
            <h3 className="text-sm text-gray-500">
              Welcome to your own co-pilot to help you accelerate your role in your company's storm operations and crew comfort during Extreme Events with the latest in outage forecasting technology.
            </h3>
          </div>
          <div className="items-center px-4 py-3">
            
            <video class="w-full" controls>
              <source src="/docs/videos/flowbite.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>

            <button
              id="ok-btn"
              className="mt-2 px-4 py-2 bg-indigo-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-300"
              onClick={() => setShowModal(false)}
            >
              Or, watch on YouTube
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
