import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CheckCircleIcon
} from '@heroicons/react/24/outline'
import { Text, TextLink } from './text'

const ExperimentCard = ({ name, date, outages, temp, wind, precipitation, status, url }) => {
  const history = useNavigate();

  const navigateToExperiment = () => {
    history(url);
  };

  return (
    <article
      onClick={navigateToExperiment}
      className="hover:animate-background rounded-xl bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 p-0.5 shadow-xl transition hover:bg-[length:400%_400%] hover:shadow-sm hover:[animation-duration:_4s] cursor-pointer"
    >
      <div className="rounded-[10px] bg-white p-4 sm:p-6">
        <div>
          <h3 className="mt-0.5 text-xl font-bold text-gray-900">{name}</h3>
          {/* <time datetime={date} className="block text-xs text-gray-500">
            {new Date(date).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </time> */}
        </div>

        <div className="mt-4">
        <div className="">
          <p className="text-4xl font-bold">{outages} Outages</p>
          <p className="text-sm">{status}</p>
        </div>
        <div className="flex mt-2">
          <div className="flex items-center mr-4">
            <span className="text-xs text-gray-400">{temp}°F avg temp</span>
          </div>
          <div className="flex items-center">
            <span className="text-xs text-gray-400">{wind} MPH max wind gusts</span>
          </div>
        </div>
        <div className="mt-1">
          <span className="text-xs text-gray-400">{precipitation}inch mean hourly prcp</span>
        </div>
      </div>

      </div>

      
    </article>
  );
}

export default ExperimentCard;
