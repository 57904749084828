import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { ArrowDownIcon, ArrowUpIcon, WifiIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon, Bars3Icon, BoltSlashIcon } from '@heroicons/react/24/outline'
import Spinner from './Spinner'
import { InventoryDialog } from './InventoryDialog';
import InventoryTable from './InventoryTable';
import { DialogActions, DialogBody, DialogDescription, DialogTitle } from './dialog'
import { Button } from './button'
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function InventoryAndCrewCardStrip() {
  const [inventoryData, setInventoryData] = useState({
    transformers: 0,
    trucks: 0,
    poles: 0,
    ohCable: 0,
});
const [isLoading, setIsLoading] = useState(false);
const [error, setError] = useState(null);
const apiURL = process.env.REACT_APP_API_URL;

let [isOpen, setIsOpen] = useState(false)

const utilityName = process.env.REACT_APP_UTILITY_NAME;

const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Function to handle opening the dialog
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  // Function to handle closing the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };


useEffect(() => {
  setIsLoading(true);
  axios.get(`${apiURL}/api/v1/inventory/${utilityName}`)
      .then(response => {
          const totals = response.data.reduce((acc, item) => {
              acc.transformers += item.TRANSFORMER_OH;
              acc.switches += item.OH_SWITCHES + item.OH_FUSES;
              acc.poles += item.POLES;
              acc.ohCable += item.CABLES;
              return acc;
          }, { transformers: 0, switches: 0, poles: 0, ohCable: 0 });
          // console.log("Success from Inventory API!")
          // console.log(response)

          setInventoryData(totals);
      })
      .catch(error => {
          console.error('There was an error fetching the inventory data:', error);
          setError(error);

      })
      .finally(() => {
          setIsLoading(false);
      });
}, []);

if (isLoading) {
  return <div><Spinner/></div>; // Show a loading indicator
}

if (error) {
  return <div>Error: {error.message}</div>; // Show an error message
}

  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900 mt-10">Asset Inventory for Repairs</h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">

        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-indigo-500 p-3">
              <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">Transformers</p>
          </dt>
          <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
            <p className="text-2xl font-semibold text-gray-900">{inventoryData.transformers}</p>
            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
              <div className="text-sm">
                <a href="#" onClick={handleOpenDialog} className="font-medium text-indigo-600 hover:text-indigo-500">
                  Details
                </a>
              </div>
            </div>
          </dd>
        </div>

        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-indigo-500 p-3">
              <BoltSlashIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">Switches & Fuses</p>
          </dt>
          <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
            <p className="text-2xl font-semibold text-gray-900">{inventoryData.switches}</p>
            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
              <div className="text-sm">
                <a href="#" onClick={handleOpenDialog} className="font-medium text-indigo-600 hover:text-indigo-500">
                  Details
                </a>
              </div>
            </div>
          </dd>
        </div>

        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-indigo-500 p-3">
              <WifiIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">Poles</p>
          </dt>
          <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
            <p className="text-2xl font-semibold text-gray-900">{inventoryData.poles}</p>
            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
              <div className="text-sm">
                <a href="#" onClick={handleOpenDialog} className="font-medium text-indigo-600 hover:text-indigo-500">
                  Details
                </a>
              </div>
            </div>
          </dd>
        </div>

        <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
          <dt>
            <div className="absolute rounded-md bg-indigo-500 p-3">
              <ArrowUpIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <p className="ml-16 truncate text-sm font-medium text-gray-500">OH Cable</p>
          </dt>
          <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
            <p className="text-2xl font-semibold text-gray-900">{inventoryData.ohCable} ft</p>
            <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
              <div className="text-sm">
                <a href="#" onClick={handleOpenDialog} className="font-medium text-indigo-600 hover:text-indigo-500">
                  Details
                </a>
              </div>
            </div>
          </dd>
        </div>
      </dl>

      {/* Dialog for displaying inventory details */}
      {isDialogOpen && (
        <InventoryDialog size="xl" onClose={handleCloseDialog} isOpen={isDialogOpen} >
          <DialogTitle>Utility Inventory Details</DialogTitle>
          <DialogDescription>
              Track and manage what's available for restoration for predicted outages
          </DialogDescription>
          <DialogBody>
            <InventoryTable />
          </DialogBody>
          <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Done</Button>
        </DialogActions>
          
        </InventoryDialog>
      )}
    </div>
  )
}
