import React, { useEffect, useState } from "react";
import SidebarComponent from "../components/SidebarComponent";
import HPStatCards from "../components/HPStatCards";
import HPExperimentsRow from "../components/HPExperimentsRow";
import WelcomeModal from "../components/WelcomeModal";
import Notification from "../components/Notification";
import Spinner from "../components/Spinner";
// import { useUser } from '@clerk/clerk-react';
import axios from 'axios'; 
import DateTimeDisplay from "../components/DateTimeDisplay";
import AppLayout from "./AppLayout";

const apiUrl = process.env.REACT_APP_API_URL;


// This function is called after successfully creating a user with ClerkJS


export default function Home() {
  
  // const [userdata, setUserdata] = useState(null);
  // const [isUserCheckComplete, setIsUserCheckComplete] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [forecastPages, setForecastPages] = useState([]);
  // const user = useUser();
  // const [ userFullName, setUserFullName ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);
  const apiURL = process.env.REACT_APP_API_URL;

  const apiUrl = process.env.REACT_APP_API_URL;

  
  // useEffect to get the summary data from the API: api/v1/forecast/summary-landingpage/
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${apiURL}/api/v1/forecast/summary-landingpage/`);
        if (response.status === 200) {
          console.log("Success!")
          console.log(response.data)
          setForecastPages(response.data);
          return <Notification message={`Success!`} notificationType="Success" />;
          // setIsUserCheckComplete(true);
        } else {
          // Handle any status code that falls outside the range of 2xx
          return <Notification message={`Error: Received status code ${response.status}`} notificationType="Error" />;
          console.log(`Error: Received status code ${response.status}`);
          setIsLoading(false);
        }
      } catch (error) {
        console.log('Error: Could not fetch the data.');
      } 
    };
    fetchData();
  }, []); // The empty array causes this effect to only run on mount

  //   // Execute the function
  //   checkAndHandleNewUser();

  //   // Dependency array to re-run effect when `user` changes
  // }, [user]);

  // console.log(userdata)

  
    return (
        <>
        <AppLayout>
             <WelcomeModal showModal={showModal} setShowModal={setShowModal} />
            <div className="min-h-full">
              <div className="bg-indigo-600 pb-64">
                <header className="py-10">
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-white">Dashboard</h1>
                  </div>
                </header>
              </div>
    
              <div className="-mt-64">
                <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                 {/* insert h2 header  */}
                    
                  <div className="rounded-lg bg-white px-5 py-6 shadow shadow-lg sm:px-6">
                  <h2 className="text-lg font-medium text-gray-900">As of</h2>
                  <DateTimeDisplay />
                    <div className="m-8 bg-white">
                        {/* if isUserCheckComplete true, show the stat card, else not*/}
                        {/* <HPStatCards  /> */}
                    </div>
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
                        <div className="lg:col-span-2">
                            <h2 className="text-lg font-medium text-gray-900 pb-10">Weather Updates</h2>
                        </div>
                        <div >
                        <h2 className="text-lg font-medium text-gray-900 pb-10">My Alerts</h2>
                        </div>
                        
                    </div>
                    
                  <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
                  
                    <div className="h-32 rounded-lg bg-white lg:col-span-2">
                        {/* <WeatherUpdatesComponent /> */}
                    </div>
                    <div className="h-32 rounded-lg bg-gray-200"></div>
                    </div>

                    <h2 className="text-lg font-medium text-gray-900 mt-6">5-Day Storm Forecasts</h2>
                    <div className="m-8 bg-white">
                        <HPExperimentsRow forecastData={forecastPages} />
                    </div>
                  </div>
                </div>
              </div>

          </div>

        </AppLayout>
          
          
          

          
        </>
        
      );
}