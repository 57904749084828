import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import ThreeDContainer from '../components/ThreeDContainer';
import InteractiveSide from '../components/InteractiveSide';
import FloatingHomeButton from '../components/FloatingHomeButton';
import MapPlayer from '../components/MapPlayer';

import SidebarComponent from '../components/SidebarComponent';
import ActionPanel from '../components/ActionPanel';
import Spinner from '../components/Spinner';
import Notification from '../components/Notification';
import AdBanner from '../components/AdBanner';

import AppLayout from '../pages/AppLayout';



function ForecastToday() {

  // useEffect to get the data from the API call

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { dateParam } = useParams();
  const [substationData, setSubstationData] = useState([{}]);
  const apiURL = process.env.REACT_APP_API_URL;

  // get and set today's date
  const today = new Date();
  const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  // Make sure it is in th "YYYY-MM-DD" format
  const todayDate = date.toString();


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');

      try {
        const response = await axios.post(`${apiURL}/api/v1/forecast/geojson/`, {
          query_date: dateParam || todayDate,
          service_areas: ["Area1", "Area3", "Area2"]
        });

        if (response.status === 200) {
          // console.log("Success!")
          // console.log(response.data)
          setData(response.data);

        } else {
          // Handle any status code that falls outside the range of 2xx
          setError(`Error: Received status code ${response.status}`);
        }
      } catch (error) {
        setError('Error: Could not fetch the data.');
      } finally {
        setLoading(false);
      }
    };

    const fetchSubstationData = async () => {
      setLoading(true);
      setError('');
      const utilityName = process.env.REACT_APP_UTILITY_NAME;
      try {
        axios.get(`${apiURL}/api/v1/substations/${utilityName}`) // Use the environment variable in the API call
        .then((response) => {
          setSubstationData(response.data); // Set the data with the response
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
          setError('Error: Could not fetch the data.');
          setLoading(false);
        });
      } catch (error) {
        setError('Error: Could not fetch the data.');
      } finally {
        setLoading(false);
      }
    }

    fetchData();
    fetchSubstationData();
  }, []); // The empty array causes this effect to only run on mount

  if (loading) {
    return <>
    <div className="min-h-full">
    <Spinner />
    </div>
    </>; 
  }

  if (error) {
    return <Notification message={error} notificationType="Error" />;
  }


  return (
    <>

      <AppLayout>
      <div className="">
          <div className="lg">
        <div className="min-h-full">
        <div className="bg-indigo-600 pb-64">
            <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-bold tracking-tight text-white">
              {dateParam || "Today"}
            </h1>
                
            </div>
            </header>
        </div>

        <main className="-mt-64">
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
                
                <div className="rounded-lg bg-white px-5 py-6 shadow shadow-xl sm:px-6">
                
                      {data ? <MapPlayer myGeoJSON={data} /> : <Spinner />}
                </div>
            </div>
            </main>
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
              {data ? <InteractiveSide data={data} substationData={substationData} /> : <Spinner />}
            </div>
            </div >
            
            
        </div>
          </div>

          <ActionPanel className="z-2000" />
      </AppLayout>
          


      
        



      
    </>
  );
}

export default ForecastToday;
