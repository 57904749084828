import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { set } from 'date-fns';

const CustomerPieChart = ( { comm_cust, resi_cust, indus_cust } ) => {

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {


        // Custom animation function
        (function (H) {
            H.seriesTypes.pie.prototype.animate = function (init) {
                // ... (include your custom animation logic here)
            };
        }(Highcharts));


    
        // Initialize the chart
        Highcharts.chart('custom-pie-chart-container', {
            chart: {
                type: 'pie'
            },
            credits: {
                enabled: true,
                href: 'https://www.plexflo.com/products/sofia',
                text: '(c) GridLeaf.org'
            },
            title: {
                text: 'Customer Classification by Rate Class'
            },
            subtitle: {
                text: 'Click the slices to view the data'
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                name: 'Count',
                colorByPoint: true,
                animation: {
                    duration: 2000
                },
                data: [{
                    name: 'Commercial',
                    y: comm_cust
                }, {
                    name: 'Residential',
                    y: resi_cust
                }, {
                    name: 'Industrial',
                    y: indus_cust
                }]
            }]
        });
    }, []);
    

    return (
        <div id="custom-pie-chart-container"></div>
    );
};

export default CustomerPieChart;
