import React from "react";
import AppLayout from "./AppLayout";
import FormScenarioBuilder from "../components/FormScenarioBuilder";
import ScenarioResultsMap from "../components/ScenarioResultsMap";

const ScenarioBuilder = () => {
    return (
        <AppLayout>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-8">
  <div className="lg:col-span-1 overflow-auto max-h-screen">
    <FormScenarioBuilder />
  </div>
  <div className="lg:col-span-3">
    <ScenarioResultsMap />
  </div>
</div>
        </AppLayout>
    );

};

export default ScenarioBuilder;
