export const msalConfig = {
    auth: {
      clientId: '80ff6dbc-ab39-4fa1-8612-15bb9cd33fb0',
      authority: `https://login.microsoftonline.com/07e1c1ec-20f9-4ae6-8e05-07b85a74ad69`,
      redirectUri: 'http://localhost:3000/home'
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your tokens are stored
      storeAuthStateInCookie: true, // Set to true if you have issues with the browser blocking third-party cookies
    }
  };