import React, { useState } from 'react';
import axios from 'axios';
import { Description, Field, FieldGroup, Fieldset, Label, Legend } from './fieldset'
import { Input } from './input'
import { Select } from './select'
import { Text } from './text'
import { Button } from './button'
import Notification from './Notification';
import Spinner from './Spinner';

function FormScenarioBuilder() {

    const [simulationStatus, setSimulationStatus] = useState(false);
    const [error, setError] = useState(null);
    const [formVisibility, setFormVisibility] = useState(true);
    const [formData, setFormData] = useState({
        windGust: 8,
        avgWindSpeed: 4,
        probabilityPrecipitation: 0.02,
        precipitation: 0.01,
        gisRegion: 'Area1',
        eventType: 'blue_sky',
        eventDuration: 'minor',
        precipitationType: 'none',
        accretionOnAssets: 0,
        accretionVariation: 0,
        aiModel: 'plexflo',
        status: '',
        
    });


    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        setSimulationStatus(true);
        try {
          const response = await axios.post('${apiURL}/api/v1/simulate-scenario', formData);
          // Assuming the API returns the contour data in the response
          const contourData = response.data;
          console.log(contourData);
          // Handle the contour data as needed
            
        } catch (error) {
          console.error('There was an error processing your request', error);
          setSimulationStatus(false);
            setError(error.message);
            setFormVisibility(true);
        }
      };

      if (simulationStatus) {
        return <div className="spinner">
            <Spinner />
        </div>; 
    }

    if (error) {
        return (
            <>
            <Notification type="error" title="Error" message={error} />
                <div className='py-10 pl-4 sm:pl-6 lg:pl-8'>
                    <form onSubmit={handleSubmit}>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        Extreme Event Scenario Builder
                    </h2>
                        <Fieldset>
                            <Legend>Weather Parameters</Legend>
                            <Text>Feel free to input different weather conditions</Text>
                            <FieldGroup>
                                <Field>
                                <Label>Type of Event</Label>
                                <Select name="eventType"  onChange={handleInputChange}>
                                <option value="blue_sky" defaultValue="blue_sky">Blue Sky</option>
                                <option value="ts">Tropical Storm</option>
                                <option value="hurricane5">Hurricane (Category 5)</option>
                                <option value="hurricane4">Hurricane (Category 4)</option>
                                <option value="hurricane3">Hurricane (Category 3)</option>
                                <option value="flash_flood">Flash Flood & Rain</option>
                                <option value="ice_storm">Ice Storm</option>
                                <option value="wild_fire">Wild Fire</option>
                                
                                </Select>
        
                                </Field>
        
                                <Field>
                                <Label>Event Duration</Label>
                                <Select name="eventDuration" onChange={handleInputChange}>
                                <option value="minor" selected>Minor</option>
                                <option value="transient">Transient</option>
                                <option value="less_than_4_hours">Less than 4 hours</option>
                                <option value="4_to_24_hours">4-24 hours</option>
                                <option value="more_than_24_hours">More than 24 hours</option>
                                </Select>
                                </Field> 
                            </FieldGroup>
                            
        
                            <FieldGroup>
                            <Field>
                                <Label>Maximum Wind Gusts (mph)</Label>
                                <Input name="windGust" onChange={handleInputChange} placeholder="8"/>
                            </Field>
                            <Field>
                                <Label>Sustained Avg Wind Gusts (mph)</Label>
                                <Input name="avgWindSpeed" onChange={handleInputChange} placeholder="4"/>
                            </Field>
                            <Field>
                                <Label>Precipitation (inches)</Label>
                                <Input name="precipitation" onChange={handleInputChange} placeholder="0.01"/>
                            </Field>
                            <Field>
                                <Label>Precipitation Type</Label>
                                <Select name="precipitationType" onChange={handleInputChange}>
                                    <option  value="none" selected>None</option>
                                <option value="rain">Rain</option>
                                <option value="freezing_rain">Freezing rain</option>
                                <option value="sleet">Sleet</option>
                                <option value="hail">Hail</option>
                                <option value="snow_blizzard">Snow & blizzard</option>
                                </Select>
                            </Field>
                            <Field>
                                <Label>Accretion on assets (inches)</Label>
                                <Input name="accretionOnAssets" onChange={handleInputChange} placeholder="0.0"/>
                            </Field>
                            <Field>
                                <Label>Accretion Variation (inches)</Label>
                                <Input name="accretionVariation" onChange={handleInputChange} placeholder="0.0"/>
                            </Field>
                            
                            </FieldGroup>
                        </Fieldset>
                        
                        <Fieldset>
                            <Legend>Analysis Settings</Legend>
        
                            <FieldGroup>
                            <Field>
                                <Label>GIS Region</Label>
                                <Select name="gisRegion" onChange={handleInputChange}>
                                <option>All Regions</option>
                                <option>Area 1</option>
                                <option>Area 2</option>
                                <option>Area 3</option>
                                </Select>
                                </Field>
        
                                <Field>
                                    <Label>Choose AI Model</Label>
                                    <Select name="aiModel" onChange={handleInputChange}>
                                        <option value="plexflo">Plexflo v2023.02.20</option>
                                        <option value="uconn">University of Connecticut - Open Source</option>
                                        <option value="sync_energy">Sync Energy AI</option>
                                        <option value="ibm">IBM WeatherChannel ML</option>
                                        <option value="dtn">DTN Weather Model</option>
                                        <option value="custom">Custom Model (Update in Settings)</option>
        
                                    </Select>
                                    </Field>
                                </FieldGroup>
        
                                
                        </Fieldset>
        
                        <Fieldset className={"pt-6"}>
                            <Button type="submit">Simulate Event</Button>
                        </Fieldset>
        
                    
        
                    </form>
                </div>
            </>
        )

    }

    if (formVisibility) {
        return (
            <>
                <div className='py-10 pl-4 sm:pl-6 lg:pl-8'>
                    <form onSubmit={handleSubmit}>
                    <h2 className="my-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Extreme Scenario Builder
        </h2>
                        <Fieldset>
                            <Legend>Weather Parameters</Legend>
                            <Text>Feel free to input different weather conditions</Text>
                            <FieldGroup>
                                <Field>
                                <Label>Type of Event</Label>
                                <Select name="eventType"  onChange={handleInputChange}>
                                <option value="blue_sky" defaultValue="blue_sky">Blue Sky</option>
                                <option value="ts">Tropical Storm</option>
                                <option value="hurricane5">Hurricane (Category 5)</option>
                                <option value="hurricane4">Hurricane (Category 4)</option>
                                <option value="hurricane3">Hurricane (Category 3)</option>
                                <option value="flash_flood">Flash Flood & Rain</option>
                                <option value="ice_storm">Ice Storm</option>
                                <option value="wild_fire">Wild Fire</option>
                                
                                </Select>
        
                                </Field>
        
                                <Field>
                                <Label>Event Duration</Label>
                                <Select name="eventDuration" onChange={handleInputChange}>
                                <option value="minor" selected>Minor</option>
                                <option value="transient">Transient</option>
                                <option value="less_than_4_hours">Less than 4 hours</option>
                                <option value="4_to_24_hours">4-24 hours</option>
                                <option value="more_than_24_hours">More than 24 hours</option>
                                </Select>
                                </Field> 
                            </FieldGroup>
                            
        
                            <FieldGroup>
                            <Field>
                                <Label>Maximum Wind Gusts (mph)</Label>
                                <Input name="windGust" onChange={handleInputChange} placeholder="8"/>
                            </Field>
                            <Field>
                                <Label>Sustained Avg Wind Gusts (mph)</Label>
                                <Input name="avgWindSpeed" onChange={handleInputChange} placeholder="4"/>
                            </Field>
                            <Field>
                                <Label>Precipitation (inches)</Label>
                                <Input name="precipitation" onChange={handleInputChange} placeholder="0.01"/>
                            </Field>
                            <Field>
                                <Label>Precipitation Type</Label>
                                <Select name="precipitationType" onChange={handleInputChange}>
                                    <option  value="none" selected>None</option>
                                <option value="rain">Rain</option>
                                <option value="freezing_rain">Freezing rain</option>
                                <option value="sleet">Sleet</option>
                                <option value="hail">Hail</option>
                                <option value="snow_blizzard">Snow & blizzard</option>
                                </Select>
                            </Field>
                            <Field>
                                <Label>Accretion on assets (inches)</Label>
                                <Input name="accretionOnAssets" onChange={handleInputChange} placeholder="0.0"/>
                            </Field>
                            <Field>
                                <Label>Accretion Variation (inches)</Label>
                                <Input name="accretionVariation" onChange={handleInputChange} placeholder="0.0"/>
                            </Field>
                            
                            </FieldGroup>
                        </Fieldset>
                        
                        <Fieldset>
                            <Legend>Analysis Settings</Legend>
        
                            <FieldGroup>
                            <Field>
                                <Label>GIS Region</Label>
                                <Select name="gisRegion" onChange={handleInputChange}>
                                <option>All Regions</option>
                                <option>Area 1</option>
                                <option>Area 2</option>
                                <option>Area 3</option>
                                </Select>
                                </Field>
        
                                <Field>
                                    <Label>Choose AI Model</Label>
                                    <Select name="aiModel" onChange={handleInputChange}>
                                        <option value="plexflo">Plexflo v2023.02.20</option>
                                        <option value="uconn">University of Connecticut - Open Source</option>
                                        <option value="sync_energy">Sync Energy AI</option>
                                        <option value="ibm">IBM WeatherChannel ML</option>
                                        <option value="dtn">DTN Weather Model</option>
                                        <option value="custom">Custom Model (Update in Settings)</option>
        
                                    </Select>
                                    </Field>
                                </FieldGroup>
        
                                
                        </Fieldset>
        
                        <Fieldset className={"pt-6"}>
                            <Button type="submit">Simulate Event</Button>
                        </Fieldset>
        
                    
        
                    </form>
                </div>
            </>
            
          )
    }
}

export default FormScenarioBuilder;